import React from "react"
import Header from "../general/header"

export default function AppLayout({ children, menu }) {
  return (
    <>
      <Header menu={menu}/>
      {children}
    </>
  )
}