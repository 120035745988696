import React, { useState } from 'react';
import JSONData from "../data/texts.json"
import AppLayout from "../components/layouts/app"
import agent from "../lib/agent"

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [ok, setOk] = useState(0);

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const submit = (e) => {
    e.preventDefault();
    agent.Password.forgot({ email: email })
    .then(res => {
      console.log(res)
      setOk(true)
    })
  }

  const Ok = () => {
    return (
      <p className="text-muted text-center mb-3">Password reset link sent</p>
    )
  }

  const Form = () => {
    return (
      <>
        <p className="text-muted text-center mb-3">Insert email to get the password reset link</p>
        <form id="forgot-password-form" action="" onSubmit={submit}>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <fieldset>
              <input id="email" type="email" placeholder="Email" className="form-control email" name="email" value={email} onChange={handleChange} required/>
            </fieldset>
          </div>

          <div className="col-lg-12">
            <fieldset>
              <button type="submit" className="form-submit main-button col-lg-12" aria-label="Submit Forgot Password">
                  <span className="hidden spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Submit
              </button>
            </fieldset>
          </div>
        </form>
      </>
    )
  }

  const page = {
    title: "Forgot password",
    description: ""
  }
  return (
    <main >
      <title>{page.title}</title>
      <AppLayout menu={JSONData.menu} page={page}>
        <div className="welcome-area" id="welcome">
          <div className="form-card-container app-form col-10 col-md-8 col-l-6">
            <h3 className="form-card-header">Forgot password</h3>
            <div className="form-card-content">
              <div className="col-lg-12">
                <div className="form-card-form">
                  {ok ? <Ok/> : <Form/>}
                </div>
            </div>
          </div>          
        </div>
        </div>
      </AppLayout>
    </main>
  )
}

export default ForgotPassword
